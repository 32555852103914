<template>
    <v-card flat class="fill-height box">
        <v-app-bar flat class="row header mx-0">
            <img :src="require('../assets/LeonardoIcon.svg')" width="75" />
            <v-divider class="mx-3" inset vertical></v-divider>
            <span class="headline">Rental Shop</span>
        </v-app-bar>
        
        <v-card-text class="row content mx-0">
            <v-row align-content="center" justify="center" no-gutters>
                <v-col cols="12" md="10" lg="7" xl="6" :class="$vuetify.breakpoint.smAndDown ? 'mb-10' : ''">
                    <v-card-title class="headline pl-0">{{ local.id == 0 ? 'Enter' : 'Confirm' }} your information</v-card-title>
                    <v-card-subtitle class="pl-0">Please fill the fields below</v-card-subtitle>

                    <v-row no-gutters>
                        <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pr-2'">
                            <v-text-field v-model="local.firstName" label="First Name" outlined :error-messages="firstNameError" maxlength="50"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pl-2'">
                            <v-text-field v-model="local.lastName" label="Last Name" outlined :error-messages="lastNameError" maxlength="50"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row no-gutters class="mt-3">
                        <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pr-2'">
                            <v-subheader class="grey--text pl-0">General Information</v-subheader>
                            <v-text-field v-model="local.phone" label="Phone" mask="phone" outlined :error-messages="phoneError" v-mask="'(###) ###-####'"></v-text-field>
                            <v-text-field v-model="local.email" label="Email" outlined :error-messages="emailError"></v-text-field>

                            <v-row no-gutters>
                                <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pr-2'">
                                    <v-text-field v-model="local.birthdate" label="Birthdate" outlined append-icon="mdi-cake-variant" 
                                        mask="date" :error-messages="birthdateError" v-mask="'##/##/####'"
                                        hint="mm/dd/yyyy" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pl-2'">
                                    <v-select v-model="local.gender" :items="genders" item-value="id" item-text="name" label="Gender" outlined :error-messages="genderError"></v-select>
                                </v-col>
                            </v-row>
                            
                            <v-text-field v-model="local.otherId" label="ID" outlined :error-messages="otherIdError" hint="We recommend the driver's license number" persistent-hint></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pl-2'">

                            <v-subheader class="grey--text pl-0">Address</v-subheader>
                            <v-text-field v-model="local.addressLine1" label="Address Line 1" outlined :error-messages="addressLine1Error" maxlength="40"></v-text-field>
                            <v-text-field v-model="local.addressLine2" label="Address Line 2" outlined maxlength="40"></v-text-field>
                        
                            <v-row no-gutters>
                                <v-col cols="12" md="5" :class="$vuetify.breakpoint.smAndDown ? '' : 'pr-2'">
                                    <v-text-field v-model="local.city" label="City" outlined :error-messages="cityError"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" :class="$vuetify.breakpoint.smAndDown ? '' : 'px-2'">
                                    <v-text-field v-model="local.state" label="State" outlined v-mask="'AA'" :error-messages="stateError"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" :class="$vuetify.breakpoint.smAndDown ? '' : 'pl-2'">
                                    <v-text-field v-model="local.zipcode" label="Zipcode" outlined v-mask="'#####'" :error-messages="zipcodeError"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>

                <v-btn absolute bottom left depressed x-large color="error" @click="restart" :icon="$vuetify.breakpoint.smAndDown">
                    <v-icon :left="!$vuetify.breakpoint.smAndDown">mdi-restart</v-icon> 
                    <span v-if="!$vuetify.breakpoint.smAndDown">Restart</span>
                </v-btn>
                
                <v-btn absolute bottom right depressed x-large color="primary" @click="nextStep" :loading="loading">
                    <v-icon left>mdi-arrow-right</v-icon> Next
                </v-btn>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { API } from '@/inc/api';
import { mapGetters, mapActions } from 'vuex';
import { _st } from '@/softech';
import { mask } from 'vue-the-mask';

export default {
    props: {
        workflow: { type: String, default: 'rent' }
    },
    data: () => ({
        customerTypes: [ 
            { id: 1, name: 'Person' },
            { id: 2, name: 'Company' }
        ],
        genders: [
            { id: 'm', name: 'Male' },
            { id: 'f', name: 'Female' },
            { id: 'n', name: 'Neutral' }
        ],
        local: {
            id: 0,
            birthdate: '',
            customerType: 1,
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            gender: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipcode: '',
            parentId: null,
            otherId: '',
        },
        birthdateError: '',
        customerTypeError: '',
        firstNameError: '',
        lastNameError: '',
        phoneError: '',
        emailError: '',
        genderError: '',
        addressLine1Error: '',
        cityError: '',
        stateError: '',
        zipcodeError: '',
        otherIdError: '',

        message: '',
        messageType: '',
        messageTitle: '',
        openMessage: false, 

        loading: false,
    }),
    mounted() {
        // if( !this.isTokenValid() )
        //     this.$router.push( '/unauthorized' );

        this.init();
    },
    computed: {
        ...mapGetters({
            storeCustomer   : 'customer',
            path            : 'path'
        }),
        header() {
            return 'New Rent';
        },
        customer() {
            return {
                id: this.local.id,
                customerType: this.local.customerType,
                firstName: this.local.firstName,
                lastName: this.local.lastName,
                fullName: this.local.firstName.trim() + ' ' + this.local.lastName.trim(),
                phone: this.local.phone,
                email: this.local.email,
                birthdate: this.formatDate(this.local.birthdate),
                gender: this.local.gender,
                addressLine1: this.local.addressLine1,
                addressLine2: this.local.addressLine2,
                addressCity: this.local.city,
                addressState: this.local.state,
                addressZipcode: this.local.zipcode,
                customerId: this.local.internalCustomerId,
                parentId: null,
                otherId: this.local.otherId,
            }
        },
        
    },
    methods: {
        ...mapActions({
            setCustomer: 'setCustomer',
        }),
        init() {
            // check that the phone number is present
            let phone = this.storeCustomer.phone;
            if( _st.isNUE( phone ) ) {
                this.$router.go(-1);
                return;
            }

            // get customer information from the store
            this.local.phone = phone;
            this.local.firstName = this.storeCustomer.firstName;
            this.local.lastName = this.storeCustomer.lastName;
            this.local.email = this.storeCustomer.email;
            this.local.birthdate = this.storeCustomer.birthdate;
            this.local.gender = this.storeCustomer.gender;
            this.local.addressLine1 = this.storeCustomer.addressLine1;
            this.local.addressLine2 = this.storeCustomer.addressLine2;
            this.local.city = this.storeCustomer.addressCity;
            this.local.state = this.storeCustomer.addressState;
            this.local.zipcode = this.storeCustomer.addressZipcode;
            this.local.otherId = this.storeCustomer.otherId;
            this.local.id = this.storeCustomer.id;
        },
        isValid() {
            let errorFound = false;
            this.customerTypeError = '';
            this.firstNameError = this.lastNameError = this.phoneError = 
            this.emailError = this.birthdateError = this.genderError = 
            this.addressLine1Error = this.cityError = this.stateError = 
            this.zipcodeError = this.otherIdError = '';

            if( this.local.customerType < 0 || this.local.customerType > 2 ) {
                this.customerTypeError = 'required';
                errorFound = true;
            }

            if( this.local.firstName.length < 3 ) {
                this.firstNameError = 'required';
                errorFound = true;
            }

            if( this.local.lastName.length < 3 ) {
                this.lastNameError = 'required';
                errorFound = true;
            }

            if( this.local.phone.length < 10 ) {
                this.phoneError = 'required';
                errorFound = true;
            }

            if( this.local.email == null || !/.+@.+/.test(this.local.email) ) {
                this.emailError = 'required';
                errorFound = true;
            }

            if( this.local.birthdate.length > 0 && !/^\d{1,2}\/\d{1,2}\/\d{4}$/g.test(this.local.birthdate) ) {
                this.birthdateError = 'invalid format (must be mm/dd/yyyy)';
                errorFound = true;
            }
            else if( this.local.birthdate.length > 0 && this.local.birthdate.substring(4, 8) > (new Date()).getFullYear() ) {
                this.birthdateError = 'birth year cannot be in the future';
                errorFound = true;
            }

            if( this.local.addressLine1.length < 3 ) {
                this.addressLine1Error = 'required';
                errorFound = true;
            }

            if( this.local.city == undefined || this.local.city.length < 2 ) {
                this.cityError = 'required';
                errorFound = true;
            }

            if( this.local.state.length < 2 ) {
                this.stateError = 'required';
                errorFound = true;
            }

            if( this.local.zipcode.length == 0 ) {
                this.zipcodeError = 'required';
                errorFound = true;
            }
            else if( !/^\d{5}(-\d{4}|)$/g.test( this.local.zipcode ) ) {
                this.zipcodeError = 'must be valid';
                errorFound = true;
            }

            if( this.local.otherId.length < 3 ) {
                this.otherIdError = 'required';
                errorFound = true;
            }

            return !errorFound;
        },
        async nextStep() {
            if( !this.isValid() )
                return;

            try {

                // create or update the customer information
                let formData = new FormData();
                formData.set('id', this.customer.id);
                formData.set('firstName', this.customer.firstName);
                formData.set('lastName', this.customer.lastName);
                formData.set('phone', this.customer.phone);
                formData.set('email', this.customer.email);
                formData.set('birthdate', this.customer.birthdate);
                formData.set('gender', this.customer.gender);
                formData.set('addressLine1', this.customer.addressLine1);
                formData.set('addressLine2', this.customer.addressLine2);
                formData.set('addressCity', this.customer.addressCity);
                formData.set('addressState', this.customer.addressState);
                formData.set('addressZipcode', this.customer.addressZipcode);
                formData.set('otherId', this.customer.otherId);

                let api = new API();

                this.loading = true;
                let res = await api.post('/customer-info', formData);

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.error == true ? 'Error' : 'Warning', res.data.message, res.data.error == true ? 'error' : 'warning' );
                    return;
                }

                let c = res.data.data;
                this.setCustomer({ 
                    id              : c.id,
                    phone           : c.phone,
                    firstName       : c.firstName,
                    lastName        : c.lastName,
                    email           : c.email,
                    birthdate       : c.birthdate,
                    gender          : c.gender,
                    otherId         : c.otherId,
                    addressLine1    : c.addressLine1,
                    addressLine2    : c.addressLine2,
                    addressCity     : c.addressCity,
                    addressState    : c.addressState,
                    addressZipcode  : c.addressZipcode,
                });

                if( this.path == 'rent' )
                    this.$router.push('/customer-measurements');
                else
                    this.$router.push('/event-outfit');
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( 'Error', error, 'error' );
            }
        },
        clearForm() {
            this.customerTypeError = '';
            this.firstNameError = this.lastNameError = this.phoneError = 
            this.emailError = this.birthdateError = this.genderError = 
            this.addressLine1Error = this.cityError = this.stateError = 
            this.zipcodeError = this.otherIdError ='';
            this.local = { 
                id: 0,
                birthdate: '',
                customerType: 1,
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                gender: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zipcode: '',
                parentId: null,
                otherId: '',
            };
        },
        formatDate(b) {
            return b.substring(6, 10) + '-' + b.substring(0, 2) + '-' + b.substring(3, 5);
        },
        parseDate(b) {
            if( b == null || b == undefined )
                return '';

            return b.split('-')[1] + '/' + b.split('-')[2] + '/' + b.split('-')[0];
        }
    },
    directives: { mask }
}
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  flex-flow: column;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}
</style>